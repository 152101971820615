import {
  ButtonPlugin,
  NavbarPlugin,
  CardPlugin,
  BadgePlugin,
  TooltipPlugin,
  SpinnerPlugin,
  CollapsePlugin,
  ToastPlugin,
  FormCheckboxPlugin,
  ModalPlugin,
  ListGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin
} from 'bootstrap-vue'

const bsPlugins = [
  ButtonPlugin,
  NavbarPlugin,
  CardPlugin,
  BadgePlugin,
  TooltipPlugin,
  SpinnerPlugin,
  CollapsePlugin,
  ToastPlugin,
  FormCheckboxPlugin,
  ModalPlugin,
  ListGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin
]

export const useBsPlugins = (Vue) => {
  bsPlugins.forEach((plugin) => {
    Vue.use(plugin)
  })
}
