import axiosInstance from '../services/axios'
import { buildRepository } from './common/baseRepository'

const namespace = 'MONITOR_REPOSITORY'

const monitorRepository = {
  getServiceList() {
    return axiosInstance.get('/service')
  },
  getLastedServiceLog(serviceId) {
    return axiosInstance.get(`/service/${serviceId}/logs/lasted`)
  },
  getLastedServiceLogError(serviceId) {
    return axiosInstance.get(`/service/${serviceId}/logs/lasted/error`)
  }
}

export default buildRepository(namespace, monitorRepository)
