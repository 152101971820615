export const getBaseToastConfig = (variant, options = {}) => {
  const toastConfig = {
    title: `Error`,
    variant,
    solid: true,
    noCloseButton: true,
    autoHideDelay: 3000,
    headerClass: 'd-none'
  }
  return {
    ...toastConfig,
    ...options
  }
}
