import monitorRepository from './monitorRepository'

const repository = {
  [monitorRepository.namespace]: monitorRepository.module
}

export const RepositoryFactory = {
  get: (name) => {
    const repo = repository[name]
    if (!repo) throw new Error(`Repository ${name} is not existed.`)
    return repo
  }
}
