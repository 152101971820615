<template>
  <b-modal
    size="lg"
    ref="logModal"
    footer-class="d-none"
    header-class="d-none"
    :visible="isVisible"
    :title="computedTitle"
  >
    <template v-if="logData">
      <template v-if="logData.service">
        <label class="font-weight-bold">Email list</label>
        <b-list-group class="mb-3">
          <b-list-group-item
            class="d-flex"
            v-for="(emailData, index) in logData.service.notification_emails"
            :key="'email_' + index"
          >
            <p class="mb-0">{{ emailData.name }}</p>
            <p class="mb-0 ml-auto">{{ emailData.email }}</p>
          </b-list-group-item>
        </b-list-group>

        <label class="font-weight-bold mb-2">SMS list</label>
        <b-list-group class="mb-3">
          <b-list-group-item
            class="d-flex"
            v-for="(smsData, index) in logData.service.notification_smses"
            :key="'phone_' + index"
          >
            <p class="mb-0">{{ smsData.name }}</p>
            <p class="mb-0 ml-auto">{{ smsData.phone }}</p>
          </b-list-group-item>
        </b-list-group>
      </template>

      <template v-if="logData.note">
        <div class="d-flex mb-3">
          <p class="font-weight-bold mb-0">Note</p>
          <p class="mb-0 ml-auto text-right">
            {{ logData.note }}
          </p>
        </div>
      </template>

      <template v-if="logData.http_code">
        <div class="d-flex mb-3">
          <p class="font-weight-bold mb-0">HTTP Code</p>
          <p class="mb-0 ml-auto">
            <b-badge
              class="px-2"
              :variant="computedHttpCode(logData.http_code)"
            >
              {{ logData.http_code }}
            </b-badge>
          </p>
        </div>
      </template>

      <template v-if="logData.http_response_time">
        <div class="d-flex mb-3">
          <p class="font-weight-bold mb-0">HTTP Response at</p>
          <p class="mb-0 ml-auto">
            {{ logData.http_response_time | toMomentDate }}
          </p>
        </div>
      </template>

      <template v-if="logData.http_test_request">
        <label class="font-weight-bold mb-2">HTTP Request</label>
        <pre class="language-json mb-3 ss-custom-height">
          <code>
            {{ logData.http_test_request | toJSONRequest }}
          </code>
        </pre>
      </template>

      <template v-if="logData.http_test_response">
        <label class="font-weight-bold mb-2">HTTP Response</label>
        <pre class="language-json mb-3 ss-custom-height">
          <code>
            {{ logData.http_test_response | toJSONResponse }}
          </code>
        </pre>
      </template>
    </template>

    <div class="d-flex justify-content-end">
      <b-button @click="closeModal" variant="primary" size="sm">
        Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment-timezone'
import { parse } from 'flatted'

export default {
  name: 'LogModal',
  props: {
    logData: Object,
    isVisible: Boolean
  },
  filters: {
    toJSONRequest(value) {
      let request = null
      try {
        request = parse(value)
      } catch (e) {
        request = JSON.parse(value)
      }

      delete request.res
      delete request.socket
      delete request.connection
      delete request._redirectable
      delete request.agent

      return request
    },
    toJSONResponse(value) {
      const response = JSON.parse(value)
      if (response instanceof Array) return parse(value)

      return {
        data: response.data,
        header: parse(response.headersResponse)
      }
    },
    toMomentDate(time) {
      return moment(time).tz('EST').format('MM/DD/YYYY HH:mm:ss') + ' EST'
    }
  },
  computed: {
    computedTitle() {
      return (
        'Detail information of ' +
        (this.logData ? this.logData.service.name : '')
      )
    },
    computedHttpCode() {
      return (status) => {
        switch (status) {
          case 200:
            return 'success'
          default:
            return 'danger'
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:logData', null)
      this.$emit('update:isVisible', false)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', this.closeModal)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/color';

.ss-custom-height {
  max-height: 200px;
}

pre[class*='language-'],
:not(pre) > code[class*='language-'] {
  background-color: $grey-color;
  box-shadow: 1px 1px 0.5em $grey-color inset;
  border-color: $grey-color;
}
</style>
