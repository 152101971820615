import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL
const version = process.env.VUE_APP_URL_VERSION

const axiosInstance = axios.create({
  baseURL: `${baseURL}/${version}`
})

axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    throw error
  }
)

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    switch (true) {
      case error.response:
        throw error.response
      default:
        throw error.message
    }
  }
)

export default axiosInstance
