<template>
  <div class="ss-card">
    <timer
      class="d-none"
      :init-time="timer.intervalTime"
      :callback="getLastedServiceLog"
    />

    <div class="ss-card--title border" v-b-toggle="collapseId">
      <span class="ss-card--title-text"> {{ service.name }} </span>
      <!-- Endpoint icon -->
      <b-icon-info-circle-fill v-b-tooltip.hover="service.endpoint" />
      <!-- Badge Status -->
      <div class="ml-auto d-flex align-items-center">
        <template v-if="log.isLoading && !log.firstCheck">
          <div class="d-inline-block mr-2">
            <b-icon-arrow-repeat class="spinner" />
          </div>
        </template>
        <template v-if="log.isLoading && log.firstCheck">
          <b-spinner class="text-white ss-card--spinner" />
        </template>
        <template v-else>
          <b-badge
            class="ss-card--badge__status"
            :variant="computeStatusVariant(log.data ? log.data.status : -1)"
          >
            {{ computeStatusLabel(log.data ? log.data.status : -1) }}
          </b-badge>
        </template>
      </div>
    </div>

    <!-- Detail Service and Log Info -->
    <b-collapse :id="collapseId" class="border border-top-0">
      <div class="ss-card--body">
        <div class="row">
          <!-- Status Service -->
          <template v-if="log.data && log.data.statusServices.length > 0">
            <div class="col-12 my-2">
              <span class="font-weight-bold text-primary"
                >Status of Services</span
              >
            </div>
            <div class="col-12">
              <table class="table table-bordered">
                <tr>
                  <th>Name</th>
                  <th>Is Enabled</th>
                  <th>Is Healthy</th>
                  <th>Message</th>
                </tr>

                <tr
                  :key="service.name"
                  v-for="service in log.data.statusServices"
                >
                  <td>{{ service.name }}</td>
                  <td class="text-center">
                    <b-checkbox
                      disabled
                      switch
                      :checked="service.status.is_enabled"
                    />
                  </td>
                  <td class="text-center">
                    <b-checkbox
                      disabled
                      switch
                      :checked="service.status.is_healthy"
                    />
                  </td>
                  <td>{{ service.status.message }}</td>
                </tr>
              </table>
            </div>
          </template>

          <div class="col-12 my-2">
            <span class="font-weight-bold text-primary">Service detail</span>
          </div>

          <div class="col-md-4 col-6 font-weight-bold mb-2">Method</div>
          <div class="col-md-2 col-6 text-right mb-2">
            <b-badge
              class="ss-card--badge"
              :variant="computeMethodVariant(service.method)"
            >
              {{ service.method }}
            </b-badge>
          </div>

          <div class="col-md-4 col-6 font-weight-bold mb-2">Category</div>
          <div class="col-md-2 col-6 text-right mb-2">
            {{ service.category }}
          </div>

          <div class="col-md-4 col-6 font-weight-bold mb-2">
            Validation Regex
          </div>
          <div class="col-md-2 col-6 text-right mb-2">
            {{ service.validation_regex }}
          </div>

          <div class="col-md-4 col-6 font-weight-bold mb-2">
            Response time expected
          </div>
          <div class="col-md-2 col-6 text-right mb-2">
            {{ numberWithCommas(service.validation_expected_response_time) }}
            ms
          </div>

          <!-- Log Detail -->
          <template v-if="log.data">
            <div class="col-12 my-2">
              <span class="font-weight-bold text-primary">Log detail</span>
            </div>

            <div class="col-md-4 col-6 font-weight-bold mb-2">
              Notification required
            </div>
            <div class="col-md-2 col-6 text-right mb-2">
              <b-form-checkbox
                disabled
                :checked="log.data.notification_required"
              />
            </div>

            <div class="col-md-4 col-6 font-weight-bold mb-2">
              Notification type
            </div>
            <div class="col-md-2 col-6 text-right mb-2">
              <b-badge
                :variant="
                  computeNotificationStatusVariant(log.data.notification_type)
                "
              >
                {{ computeNotificationStatusLabel(log.data.notification_type) }}
              </b-badge>
            </div>

            <div class="col-md-4 col-6 font-weight-bold mb-2">
              Last checked at
            </div>
            <div class="col-md-2 col-6 text-right mb-2">
              {{ computeTimeStamp(log.data.lastChecked) }}
            </div>

            <div class="col-md-4 col-6 font-weight-bold mb-2">
              Next check in
            </div>
            <div class="col-md-2 col-6 text-right mb-2">
              <timer :init-time="timer.intervalTime" />
            </div>
          </template>

          <div v-if="log.data" class="col-12 text-center mt-2 pointer-event">
            <a
              class="text-primary ss-card--pointer"
              :class="{ 'mr-2': errorLog.data }"
              @click="triggerModal"
            >
              View detail
            </a>

            <a
              v-if="errorLog.data"
              class="text-primary ss-card--pointer ml-2"
              @click="triggerErrorModal"
            >
              View lasted error log
            </a>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BIconInfoCircleFill, BIconArrowRepeat } from 'bootstrap-vue'
import { RepositoryFactory } from '@/repository'
import { getBaseToastConfig } from '@/shared/toast.util'
import moment from 'moment-timezone'
import monitorRepository from '@/repository/monitorRepository'
import Timer from '@/components/timer/Timer'

const _monitorRepository = RepositoryFactory.get(monitorRepository.namespace)

export default {
  name: 'StatusService',
  components: {
    Timer,
    BIconInfoCircleFill,
    BIconArrowRepeat
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      autoCheckId: null,
      collapseId: 'id_' + Date.now(),
      timer: {
        id: null,
        countDown: 0,
        intervalTime: 60000 * 3
      },
      log: {
        isLoading: false,
        firstCheck: true,
        data: null
      },
      errorLog: {
        isLoading: false,
        data: null
      }
    }
  },
  computed: {
    numberWithCommas() {
      return (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    computeMethodVariant() {
      return (method) => {
        switch (method) {
          case 'GET':
            return 'success'
          case 'POST':
            return 'danger'
        }
      }
    },
    computedHttpCode() {
      return (status) => {
        switch (status) {
          case 200:
            return 'success'
          default:
            return 'danger'
        }
      }
    },
    computeStatusVariant() {
      return (status) => {
        switch (status) {
          case 0:
            return 'success'
          case 1:
            return 'warning'
          case 2:
            return 'danger'
          default:
            return 'secondary'
        }
      }
    },
    computeNotificationStatusVariant() {
      return (status) => {
        switch (status) {
          case 0:
            return 'secondary'
          case 1:
            return 'danger'
          case 2:
            return 'success'
        }
      }
    },
    computeNotificationStatusLabel() {
      return (status) => {
        switch (status) {
          case 0:
            return 'PENDING'
          case 1:
            return 'ERROR'
          case 2:
            return 'SUCCESS'
        }
      }
    },
    computeStatusLabel() {
      return (status) => {
        switch (status) {
          case 0:
            return 'SUCCESS'
          case 1:
            return 'WARNING'
          case 2:
            return 'ERROR'
          default:
            return 'PENDING'
        }
      }
    },
    computeTimeStamp() {
      return (time) =>
        moment(time).tz('EST').format('MM/DD/YYYY HH:mm:ss') + ' EST'
    }
  },
  methods: {
    async getLastedServiceLog() {
      this.log.isLoading = true
      try {
        const data = await _monitorRepository.getLastedServiceLog(
          this.service.id
        )
        if (data) {
          data.statusServices = this.buildServicesStatus(data)
          data.lastChecked = new Date()
          this.log = {
            ...this.log,
            data,
            firstCheck: false
          }
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(
          `Cannot get lasted log of service ${this.service.name}`,
          getBaseToastConfig('danger')
        )
      } finally {
        this.log.isLoading = false
      }
    },
    async getLastedServiceLogError() {
      this.errorLog.isLoading = true
      try {
        this.errorLog.data = await _monitorRepository.getLastedServiceLogError(
          this.service.id
        )
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(
          `Cannot get lasted log of service ${this.service.name}`,
          getBaseToastConfig('danger')
        )
      } finally {
        this.errorLog.isLoading = false
      }
    },
    triggerModal() {
      const logData = this.log.data || {}
      const service = { service: this.service }
      this.$emit('toggleDataLogModal', { ...logData, ...service })
    },
    triggerErrorModal() {
      const logData = this.errorLog.data || {}
      const service = { service: this.service }
      this.$emit('toggleDataLogModal', { ...logData, ...service })
    },
    buildServicesStatus(data) {
      if (!data.http_test_response) return []
      try {
        const response = JSON.parse(data.http_test_response)
        if (
          response instanceof Object &&
          response.data &&
          response.data instanceof Object
        ) {
          return Object.keys(response.data).map((serviceName) => ({
            name: serviceName,
            status: response.data[serviceName]
          }))
        } else {
          return []
        }
      } catch (e) {
        return data
      }
    }
  },
  created() {
    this.getLastedServiceLog()
    this.getLastedServiceLogError()
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/color';

.ss-card {
  text-align: left;
  background-color: $light-color;

  &--title {
    padding: 0.5rem 1rem;
    background-color: $grey-color;
    color: $light-color;
    display: flex;
    align-items: center;

    &-text {
      padding-right: 10px;
      font-weight: bold;
    }
  }

  &--badge {
    width: 50px;
    text-align: center;
  }

  &--body {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  &--spinner {
    width: 20px;
    height: 20px;
  }

  &--pointer {
    color: $light-color;
    cursor: pointer;

    &::v-deep svg {
      outline: none !important;
    }

    &:hover {
    }
  }
}

.spinner {
  animation: spinner-border 0.75s linear infinite;
}
</style>
