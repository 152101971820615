<template>
  <span>{{ formatTimer }}</span>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'Timer',
  props: {
    initTime: Number,
    callback: Function
  },
  data() {
    const time = this.$props.initTime
    return {
      id: null,
      time
    }
  },
  computed: {
    formatTimer() {
      return moment.utc(this.time).format('mm:ss')
    }
  },
  methods: {
    createTimer() {
      this.id = setInterval(() => {
        this.time = this.time - 1000
        if (this.time <= 0) {
          this.time = this.initTime
          if (this.callback) {
            this.callback()
          }
        }
      }, 1000)
    },
    destroyTimer() {
      if (this.time) {
        clearInterval(this.id)
      }
    }
  },
  created() {
    this.createTimer()
  },
  beforeDestroy() {
    this.destroyTimer()
  }
}
</script>
