import Vue from 'vue'
import App from './App.vue'
import { useBsPlugins } from './plugins/bootstrap'
import { useCommonPlugins } from '@/plugins/common'

// import styles
import '@/assets/styles/main.scss'
// import bs styles
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// prismjs - highlight syntax code
import 'prismjs/themes/prism-dark.css'

Vue.config.productionTip = false

// use Bs plugins
useBsPlugins(Vue)
useCommonPlugins(Vue)

new Vue({
  render: (h) => h(App)
}).$mount('#app')
