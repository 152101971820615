<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-6 col-12 p-0 d-flex text-left">
        <b-input
          v-model="pagination.s"
          type="text"
          placeholder="Search name of project"
        />
      </div>
    </div>
    <div v-if="serviceList.isLoading" class="py-5">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="row justify-content-center">
        <div
          :key="service.id"
          v-for="(service, index) in computeDataSource"
          class="col-12 p-0"
          v-show="service.isMatched"
        >
          <status-service
            :class="{ 'mb-3': index + 1 !== serviceList.total }"
            :service="service"
            @toggleDataLogModal="showModal"
          />
        </div>
      </div>
      <div v-show="isAllEmpty">
        <p>List service is empty</p>
      </div>
    </div>
    <!-- Log data modal-->
    <log-modal
      ref="logModal"
      :log-data.sync="logModal.data"
      :is-visible.sync="logModal.isVisible"
    />
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repository'
import { getBaseToastConfig } from '@/shared/toast.util'
import monitorRepository from '@/repository/monitorRepository'
import StatusService from '@/components/status/StatusService'
import LogModal from '@/components/modal/LogModal'

const _monitorRepository = RepositoryFactory.get(monitorRepository.namespace)

export default {
  name: 'StatusServiceList',
  components: { LogModal, StatusService },
  data() {
    return {
      pagination: {
        sort_dir: 1,
        sort_by: 'name',
        s: ''
      },
      serviceList: {
        isLoading: true,
        data: [],
        total: 0
      },
      logModal: {
        data: null,
        isVisible: false
      }
    }
  },
  computed: {
    computeDataSource() {
      const data = this.serviceList.data
        .map((data) => {
          const keyword = this.pagination.s
          data.isMatched =
            !keyword || data.name.toLowerCase().includes(keyword.toLowerCase())
          return data
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      return this.pagination.sort_dir === -1 ? data.reverse() : data
    },
    isAllEmpty() {
      return this.computeDataSource.every((data) => !data.isMatched)
    }
  },
  methods: {
    async getStatusList(toggleLoader = false) {
      toggleLoader && (this.serviceList.isLoading = true)

      try {
        const { data, total } = await _monitorRepository.getServiceList()
        this.serviceList = { ...this.serviceList, data, total }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(
          'Cannot fetch status list' + JSON.stringify(e),
          getBaseToastConfig('danger')
        )
      } finally {
        this.serviceList.isLoading = false
      }
    },
    showModal(logData) {
      this.logModal = {
        data: logData,
        isVisible: true
      }
    }
  },
  created() {
    this.getStatusList(true)
  }
}
</script>
