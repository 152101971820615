<template>
  <div id="app">
    <b-navbar
      sticky
      class="px-5 shadow-sm ss-navbar"
      variant="light"
      type="light"
    >
      <b-navbar-nav>
        <h1 class="mb-0">Channel Precision System Status</h1>
      </b-navbar-nav>
    </b-navbar>
    <div class="ss-container text-center p-5">
      <status-service-list />
    </div>
  </div>
</template>

<script>
import StatusServiceList from '@/components/status/StatusServiceList'

export default {
  name: 'AppComponent',
  components: {
    StatusServiceList
  }
}
</script>

<style lang="scss" scoped>
.ss-navbar {
  min-height: 60px;

  ::v-deep .navbar-nav {
    height: 100%;
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 1.4rem;
  }
}
</style>
